<template>
  <div class="vld-parent">
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
      loader="bars"
      background-color="#ffffff"
      color="#000080"
      blur="15px"
      opacity="15.5"
    />
  </div>
  <NavBar />
  <div id="invoiceMainSection" class="invoice divBackground mp-content">
    <InvoiceHeader :bookingData="savedData" />
    <div id="secondSection" class="container-fluid">
      <div class="row d-lg-flex justify-content-between mx-0">
        <div
          class="
            col-lg-4 col-sm-12 col-md-12
            mt-sm-3 mt-lg-0 mt-md-3 mt-3
            mb-3 mb-lg-3 mb-sm-3
          "
        >
          <BookingStatus :bookingStatus="savedData" />
        </div>
        <div
          class="
            col-lg-8 col-sm-12 col-md-12
            mt-sm-3 mt-lg-0 mt-md-3 mt-3
            mb-3 mb-lg-3 mb-sm-3
          "
        >
          <DeliveryDetails :deliveryDetails="savedData" />
        </div>
      </div>
      <div class="row mx-0">
        <div id="invoiceLuggagesDiv" class="col-lg-6 col-md-12 col-sm-12">
          <Luggages :luggages="savedData" />
        </div>
        <div id="invoiceBookingDetails" class="col-lg-6 col-md-12 col-sm-12">
          <div id="invoiceBookingDetailsDiv">
            <BookingDetails :bookingDetails="savedData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceHeader from "../modules/invoice/InvoiceHeader";
import BookingStatus from "../common/components/invoice/BookingStatus";
import DeliveryDetails from "../modules/invoice/DeliveryDetails";
import BookingDetails from "../modules/invoice/BookingDetails";
import Luggages from "../modules/invoice/Luggages";
import NavBar from "../common/components/booking/NavBar";
import InvoiceBookingDetailsService from "../services/InvoiceBookingDetailsService";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Invoice",
  components: {
    NavBar,
    Luggages,
    BookingDetails,
    DeliveryDetails,
    BookingStatus,
    InvoiceHeader,
    Loading,
  },
  data() {
    return {
      savedData: {},
      invoiceSummory: {},
      isLoading: false,
      fullPage: true,
    };
  },

  mounted() {
    this.isLoading = true;
    InvoiceBookingDetailsService.getInvoiceBookingDetails(
      this.$route.params.bookinID
    )
      .then((response) => {
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
        this.$store.dispatch("setSavedDetailsToStore", response.data);
        this.savedData = response.data;
      })
      .catch((error) => {});
  },
};
</script>

<style scoped>
.form-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}
.invoice {
  width: 100%;
  height: 100%;
  padding-left: 50px;
  background: #f8f8f8;
}

#secondSection {
  background: #f8f8f8;
  margin-top: 20px;
}
.closed {
  width: calc(100% - 102px) !important;
  margin-left: 100px !important;
  transition: all 0.5s ease;
}
.mp-content {
  width: calc(100% - 280px);
  margin-left: 269px;
  transition: all 0.5s ease;
  padding: 0px;
}
@media (max-width: 768px) {
  .mp-sidebar {
    width: 70px;
  }

  .mp-content {
    width: calc(100% - 70px);
    margin-left: 70px;
    float: left;
  }
}
@media all and (max-width: 1024px) {
}

@media all and (max-width: 768px) {
  #secondSection {
    margin-top: 0;
  }
}

@media all and (max-width: 425px) {
  #secondSection {
    margin-top: 0;
  }
}
</style>
